// import logo from './logo.svg';
import './App.css';

function toggleNav() {

  if (document.getElementById('navBarLinks').classList.contains('invisible')) {
    document.getElementById('navBarLinks').classList.remove(['invisible']);
    document.getElementById('navBar').classList.add(['expanded']);



  } else {
    document.getElementById('navBarLinks').classList.add(['invisible']);
    document.getElementById('navBar').classList.remove(['expanded']);


  }

}


function goToStore() {
  let os = getMobileOperatingSystem();
  if (os === "Android") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.enabol.marve";
  } else if (os === "iOS") {
    window.location.href = "https://apps.apple.com/us/app/marve/id1523187653";
  } else {

    document.getElementById('messageBoxOverlay').style.display = "block";

  }
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}




function NavBar() {
  return <div id='navBar' className="snapArea">
    <div style={{ width: "100%" }}>
      <img id="navBarLogo" src="assets/logo.svg" alt='logo' ></img>
      <img id="navBarWrittenLogo" src="assets/written_logo.svg" alt='writtenLogo'></img>
    </div>

    <div id='navBarLinks' className="invisible">
      <span onClick={goToStore} className="navBarLink">Download</span>
      <span onClick={()=>{document.getElementById('contactBoxOverlay').style.display = "block";
}} className="navBarLink">Contact</span>
    </div>

    <div id='menu' style={{ position: "absolute", right: "27px", top: "23px" }}
      onClick={toggleNav}
    >
      <img style={{ height: "20px" }} src="assets/bars.svg " alt="" />


    </div>








  </div>


}

function Pages() {
  return <div style={{}}>

    <Intro></Intro>
    <h1 style={{ textAlign: 'center', margin: "10vh 3vh 10vh 3vh" }} className="transition blue mainTile snapArea">This is how Marve works.</h1>
    <Cooking></Cooking>
    <Map></Map>
    <Exchange></Exchange>
    <Chat></Chat>
    <Reviews></Reviews>
    <Location></Location>
    <Download></Download>

  </div>


}





function Intro() {

  return <div id="intro" className="row mainTile">

    <div >
      <h1 className='blue headline'>
        Slow down. Pause fast food. <br />Order fresh <span className="underline">homecooked</span> food.

      </h1>
      <p className="blue following" style={{ fontSize: "1.5 rem" }}>
        Find meals being cooked in your area right now.<br />Like something? Ask them to cook you a plate.
      </p>
    </div>


    <div >
      {/* <FramedScreenshot filepath="assets/IMG_0007-01.png" /> */}
      <img src="assets/img1.png" alt="Marve Eat Tab Screenshot" />

    </div>
    <div id="backgroundShape1" />


  </div>

}


function Cooking() {

  return <div id="cooking" className="row mainTile snapArea page" >

    <div className="column center">
      <img src="assets/img2.png" alt="" />
    </div>
    <div >

      <h2 className='blue leading'>
        Chefs post whatever they are cooking next in their daily routine. </h2>

      <p className="blue following" >
        For example-  A chef will start preparing Chicken Biryani at 5:30 PM. All orders need to be placed before then. The meal will be ready at 7 PM and available for Takeaway or Delivery. </p>
    </div>
    <div id="backgroundShape2"></div>
    <div id="backgroundShape3"></div>




  </div>

}


function Map() {
  return <div className="snapArea" style={{ height: "100vh", margin: "0", overflow: "hidden", display: "flex", justifyContent: "center", position: 'relative' }}>

    <img src="assets/osm.png" alt="A 2d map of a town." style={{ height: "100%", zIndex: "0" }} />
    <div style={{ position: "absolute", height: "100%", zIndex: "1", width: "100%" }}>
      <div className="mainTile" style={{ backgroundColor: "rgb(255 255 255 / 60%)", padding: "15px 5%", position: "absolute", bottom: "10%", boxShadow: "0px 0px 20px 10px #0000003b", backdropFilter: "blur(14px)" }}>
        <h2 className="leading blue">
          You, your family, your neighbors- anyone can place an order and request the chef to prepare a serving.
        </h2>
      </div>
      <div className="mainTile"  >


       
        {/* <MapMarker height="12vh" food="Chow Mein" time="7:30 PM" day="Today" top="35%" left="20%" ></MapMarker>
        <MapMarker height="10vh" food="Lasagna Rolls" time="4:00 PM" day="Tomorrow" top="25%" left="80%" ></MapMarker>
        <MapMarker height="17vh" top="15%" left="50%" day="Today"></MapMarker> */}
        <img src="assets/meal1.svg" style={{height:"12vh", position:"absolute", transform: "translate(-50%, -50%)", top: "42%", left:"30%" }} alt="" />
        <img src="assets/meal3.svg" style={{height:"10vh", position:"absolute", transform: "translate(-50%, -50%)", top: "30%", left:"75%" }} alt="" />
        <img src="assets/meal2.svg" style={{height:"17vh", position:"absolute", transform: "translate(-50%, -50%)", top: "15%", left:"50%" }} alt="" />

      </div>


    </div>



  </div>
}

function Exchange() {

  return <div id="exchange" className="mainTile page column snapArea">
    <h1 style={{ height: "25%", display: 'flex', justifyContent: "center", alignItems: "center" }} className="blue leading">
      Choose from..
    </h1>

    <div className="row" style={{}}>
      <div className="column center"  >
        <img className="exchangeMethodIcon" src="assets/takeaway.svg" alt="Takeaway box" />
        <p className="salmon leading">Takeaway</p>
      </div>
      <div className="column center" >
        <img className="exchangeMethodIcon" src="assets/delivery.svg" alt="Delivery Bike" />
        <p className="salmon leading">Delivery</p>
      </div>
      <div className="column center" >
        <img className="exchangeMethodIcon" src="assets/dine.svg" alt="Dine Utensils." />
        <p className="salmon leading">Dine</p>
      </div>
    </div>

    <p style={{ height: "25%", textAlign: "center", display: 'flex', justifyContent: "center", alignItems: "center" }} className="blue leading">..depending on what the Chef provides.</p>


  </div>
}


function Chat() {
  return <div id="chat" className="mainTile page column snapArea" >

    <div className="column center" style={{ height: "50%" }}>

      <img style={{ height: "60%" }} src="assets/chat.svg" alt="" />
      <h1 className="leading blue" style={{ textAlign: "center" }}>Dont stop there. Chat with each other. </h1>
    </div>

    <div className="column" style={{ height: "50%", width: "100%", justifyContent: "flex-end" }}>
      <ChatBubble message="Hi, I am wondering if I can come 15 mins early?" sender={true}></ChatBubble>
      <ChatBubble message="sure you can- I'll prepare yours a bit earlier" sender={false}></ChatBubble>
      <div className="row center" style={{ width: "100%", marginBottom: "1%" }}>
        <div className="salmon phoneText" style={{ fontSize: "calc(7px + 1vw)", color: "var(--primaryColor)", margin: "2% 2% 2% 1%", border: "1px solid var(--primaryColor)", padding: "14px", borderRadius: "17px", display: "flex", flexGrow: "100" }}>
          Say Something..
        </div>
        <img style={{ maxHeight: "7vh", width: "10%" }} src="assets/send.svg" alt="Send Message Icon" />
      </div>
    </div>
    {/* <div style={{ display: "none", position: "absolute", height: "15px", width: "40vw", backgroundColor: "var(--primaryColor)", left: "-7vw", top: "0px", transform: "translate(0%, -%50%)" }}></div> */}




  </div>



}

function Reviews() {

  return <div id="reviews" className="mainTile page column center snapArea">

    <div className="column center" >
      <img src="assets/eat.svg" alt="" />
      <h1 className="leading blue">Order with confidence.</h1>

    </div>

    <div className="row center" style={{ backgroundColor: '' }}>

      <div className="column center">

        <img src="assets/review.svg" alt="Review" />
        <p className="following blue">Read reviews that customers have left. </p>

      </div>

      <div className="column center">


        <img src="assets/leave-review.svg" alt="Leave Review" />
        <p style={{ textAlign: "center" }} className="following blue">Leave your own review after your purchase. </p>

      </div>
    </div>
  </div>



}

function Location() {

  return <div id="location" className="mainTile page column snapArea" >

    <h1 className="leading blue">This is where Marve works.</h1>
    <div className="row">
      <div className="column">
        <img src="assets/canada.svg" alt="Toronto, Canada" />
        <p className="following green">Toronto, Canada</p>
      </div>
      <div className="column">
        <img src="assets/pakistan.svg" alt="Lahore, Pakistan" />
        <p className="following green">Lahore, Pakistan</p>
      </div>
    </div>



  </div>



}


function Download() {

  return <div id="download" className="mainTile column snapArea" >
    <img src="assets/dots.svg" alt="dots" />
    <h2 className="following">So, What are you waiting for?
    </h2>
    <div className="headline salmon">The only place for fresh homecooked food is Marve.<br /><br />Download Marve now and find 100+ chefs to order from in your area.</div>
    <div className="row" >
      <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.enabol.marve")} src="assets/gp_badge.svg" alt="Google Play Store" />
      <img onClick={()=>window.open( "https://apps.apple.com/us/app/marve/id1523187653")} src="assets/ios_badge.svg" alt="iOS Appstore" />

    </div>


  </div>

}






// function IPhoneBorder({ borderFill = '#fff', borderStroke = '#707070', width = "auto", height = "intial" }) {

//   return <svg
//     style={{ position: "relative" }}
//     xmlns="http://www.w3.org/2000/svg"
//     width={width}
//     height={height}
//     viewBox="0 0 1112.2 2203.5"
//     overflow="visible"
//   >
//     <path
//       d="M13.5 794.7c-11-.1-12.1-6.5-12.1-15.1.3-42 .3-83.9 0-125.9-.1-8.5.6-15.3 11.6-15.1v-19.8C.8 619.9 1.5 611.6 1.5 603c.2-41.5.2-83.1-.1-124.6-.1-8.6.8-15.3 11.7-15.3v-70.3c-11.1.1-11.9-7.1-11.7-16 .5-19.9.1-39.8.2-59.8 0-11 .1-11 11.6-15 0-39.1-.8-78.5.3-117.8.6-20.2 2.6-40.7 7.3-60.3C35.9 61 90.5 8.6 163.6 4c29.9-1.9 59.9-2.2 89.9-2.2 224.8-.2 449.6-.7 674.5-.1 93.7.3 160.7 61 169 152.2 2.5 27.3 2 55 2.1 82.5.3 71.2.1 142.4.2 213.6 0 6.4-2.2 14.1 8.5 15.1 1.3.1 2.9 5.6 2.9 8.6.2 44.5.4 89-.2 133.5-.1 4.6-5 9.2-7.6 13.7-1.3-.5-2.7-1-4-1.6v13.8c.1 447.1.4 894.3.2 1341.4 0 32.1-.2 64.7-5.4 96.2-11.6 70.1-70.7 123.3-141.5 128.1-44.7 3.1-89.6 2.7-134.4 2.7-210.9.3-421.7.9-632.6-.1-70.3-.3-125.4-29.8-156.1-96.2-12.7-27.4-15.8-56.9-15.8-86.7.1-402.2.2-804.4.4-1206.6-.2-5.4-.2-10.9-.2-17.2zm1019.3 307.1V191.3c0-8.5.1-17-.2-25.4-1.7-49.2-34.4-88.7-83.2-94.1-36-4-72.7-2.1-109-2.6-13.4-.2-17.6 4.3-18.7 18.3-2.7 36.1-19.5 53.4-55.5 56.9-6.3.6-12.7.6-19 .6H367.3c-8.9 0-17.8-.5-26.6-1.5-24-2.7-40.9-17.3-46.4-40.4-1.5-6.1-1.5-12.6-2.2-18.9-1.1-9.9-6.1-15.1-16.6-15-32.6.3-65.2-.5-97.8.4-46.4 1.3-82.4 28.7-93.4 72-3.8 14.9-4.4 31-4.4 46.6-.2 609.1-.2 1218.2-.2 1827.4 0 7.2-.2 14.4.3 21.6 2.2 34.6 15.6 63 45.6 82.1 18.1 11.5 38.2 15.6 59.4 15.6h743.2c2.5 0 5.1 0 7.6-.2 44-2.2 79-26.9 91-66.8 4.4-14.7 5.9-30.8 5.9-46.3.1-306.8.1-613.3.1-919.8z"
//       fill={borderFill}
//       stroke={borderStroke}
//       strokeWidth={2.835}
//       strokeMiterlimit={10}
//     />
//     <path
//       fill="none"
//       stroke="#000"
//       strokeWidth={11.339}
//       strokeLinecap="round"
//       strokeMiterlimit={10}
//       d="M391.9 2108.1l329-.5"
//     />
//   </svg>

// }


function Footer() {
  return <div id="footer" className="snapArea">
    <a target="_blank" rel="noopener noreferrer" href="http://marve.app/terms.html"><span>Terms Of Service</span></a>
    <span> • </span>
    <a target="_blank" rel="noopener noreferrer" href="http://marve.app/privacy-policy.html"><span>Privacy Policy</span></a>
    <span> • </span>
    <a target="_blank" rel="noopener noreferrer" href="http://enabol.com"><span>All Rights Reserved. Copyright © 2021 Enabol, Inc.
    </span></a>


  </div>
}



// function MapMarker({ food = "Chicken Biryani", time = "7:00 PM", day = "Tomorrow", height = "intial", top = "50%", left = "50%" }) {

//   return <div style={{ height: height, aspectRatio: "1.90625", display: "block", position: "absolute", left: left, top: top, transform: "translate(-50%, -50%)" }}>
//     <svg
//       style={{ position: "absolute", zIndex: "0", top: "0", left: "0" }}
//       xmlns="http://www.w3.org/2000/svg"
//       // width={width}
//       height="100%"
//       viewBox="0 0 829.5 435.5"
//       overflow="visible"
//     >
//       <path
//         d="M779.4 0H50.1C22.4 0 0 22.4 0 50.1v202.5c0 27.7 22.4 50.1 50.1 50.1h249.1c17.6 0 33.9 9.3 43 24.4l64.9 108.5 65-108.6c9-15.1 25.4-24.4 43-24.4h264.3c27.7 0 50.1-22.4 50.1-50.1V50.1c0-27.7-22.4-50.1-50.1-50.1z"
//         fill="#fff"
//       />
//     </svg>
//     <div style={{ zIndex: "1", position: "absolute", height: "69.46039035591274%", width: "100%" }}>
//       <div style={{ height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
//         <div className="phoneText" style={{ padding: "7px", fontWeight: "bold", width: "60%", fontSize: "calc(" + height + "/7)" }}>{food}</div>
//         <div style={{ width: "40%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             // width={868.2}
//             height="33%"
//             viewBox="0 0 868.2 612.1"
//             overflow="visible"
//           >
//             <style />
//             <g id="prefix__XMLID_6_">
//               <linearGradient
//                 id="prefix__SVGID_1_"
//                 gradientUnits="userSpaceOnUse"
//                 x1={77.15}
//                 y1={5.629}
//                 x2={92.198}
//                 y2={641.387}
//               >
//                 <stop offset={0} stopColor="#fff" stopOpacity={0} />
//                 <stop offset={0.046} stopColor="#fff3f1" stopOpacity={0.046} />
//                 <stop offset={0.194} stopColor="#ffd1c8" stopOpacity={0.194} />
//                 <stop offset={0.347} stopColor="#ffb4a6" stopOpacity={0.347} />
//                 <stop offset={0.501} stopColor="#ff9e8c" stopOpacity={0.501} />
//                 <stop offset={0.659} stopColor="#ff8e79" stopOpacity={0.659} />
//                 <stop offset={0.823} stopColor="#ff856e" stopOpacity={0.823} />
//                 <stop offset={1} stopColor="#ff826a" />
//               </linearGradient>
//               <path
//                 d="M178.5 611.1v.1l-89.8-1.7h-.1c-6.9-6.7-55.4-55.1-72.9-98.5-18.9-46.7-22.3-103.1 0-169.3 22.3-66.2 63.5-56.4 51.5-194.6C55.1 9.1 15.7 1.3 15.7 1.3h89V2s39.4 7.8 51.4 146-29.2 128.4-51.4 194.6-18.9 122.6 0 169.3c18.9 46.7 73.8 99.2 73.8 99.2z"
//                 fill="url(#prefix__SVGID_1_)"
//               />
//             </g>
//             <g id="prefix__XMLID_5_">
//               <linearGradient
//                 id="prefix__SVGID_2_"
//                 gradientUnits="userSpaceOnUse"
//                 x1={441.11}
//                 y1={-1.195}
//                 x2={456.158}
//                 y2={634.563}
//               >
//                 <stop offset={0} stopColor="#fff" stopOpacity={0} />
//                 <stop offset={0.046} stopColor="#fff3f1" stopOpacity={0.046} />
//                 <stop offset={0.194} stopColor="#ffd1c8" stopOpacity={0.194} />
//                 <stop offset={0.347} stopColor="#ffb4a6" stopOpacity={0.347} />
//                 <stop offset={0.501} stopColor="#ff9e8c" stopOpacity={0.501} />
//                 <stop offset={0.659} stopColor="#ff8e79" stopOpacity={0.659} />
//                 <stop offset={0.823} stopColor="#ff856e" stopOpacity={0.823} />
//                 <stop offset={1} stopColor="#ff826a" />
//               </linearGradient>
//               <path
//                 d="M542.6 610.1v.1l-89.8-1.7h-.1c-6.9-6.7-55.4-55.1-72.9-98.4-18.9-46.7-22.3-103.2 0-169.3 22.3-66.2 63.5-56.4 51.5-194.6S379.8.2 379.8.2h89V1s39.5 7.8 51.5 146-29.2 128.4-51.5 194.6c-22.3 66.2-18.9 122.6 0 169.3 18.9 46.6 73.8 99.2 73.8 99.2z"
//                 fill="url(#prefix__SVGID_2_)"
//               />
//             </g>
//             <g id="prefix__XMLID_2_">
//               <linearGradient
//                 id="prefix__SVGID_3_"
//                 gradientUnits="userSpaceOnUse"
//                 x1={766.126}
//                 y1={-7.754}
//                 x2={781.174}
//                 y2={628.004}
//               >
//                 <stop offset={0} stopColor="#fff" stopOpacity={0} />
//                 <stop offset={0.046} stopColor="#fff3f1" stopOpacity={0.046} />
//                 <stop offset={0.194} stopColor="#ffd1c8" stopOpacity={0.194} />
//                 <stop offset={0.347} stopColor="#ffb4a6" stopOpacity={0.347} />
//                 <stop offset={0.501} stopColor="#ff9e8c" stopOpacity={0.501} />
//                 <stop offset={0.659} stopColor="#ff8e79" stopOpacity={0.659} />
//                 <stop offset={0.823} stopColor="#ff856e" stopOpacity={0.823} />
//                 <stop offset={1} stopColor="#ff826a" />
//               </linearGradient>
//               <path
//                 d="M867.8 611.7v.1l-89.8-1.7h-.1c-6.9-6.7-55.4-55.1-72.9-98.4-18.9-46.7-22.3-103.2 0-169.3 22.3-66.2 63.5-56.4 51.5-194.6S705 1.9 705 1.9h89v.7s39.5 7.8 51.5 146S816.3 277 794 343.2c-22.3 66.2-18.9 122.6 0 169.3 18.9 46.7 73.8 99.2 73.8 99.2z"
//                 fill="url(#prefix__SVGID_3_)"
//               />
//             </g>
//           </svg>
//           <div style={{ height: "3px" }}></div>
//           <div style={{ fontSize: "calc(" + height + "/8)" }} className="phoneText">{time}</div>
//           <div style={{ fontSize: "calc(" + height + "/8)" }} className="phoneText">{day}</div>
//         </div>
//       </div>

//     </div>

//   </div>
// }


// function FramedScreenshot(props) {
//   return <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
//     <div style={{ zIndex: "2", position: "absolute", height: "100%", width: "100%" }}>
//       <IPhoneBorder height='100%' />
//     </div  >
//     <img style={{ zIndex: "1", position: "absolute", maxHeight: "100%", maxWidth: "100%" }} src={props.filepath} alt="Screenshot of a phone." ></img>




//   </div>
// }

function ChatBubble({ message = "Hello World", sender = true }) {
  return <div className="phoneText" style={{ fontSize: "calc(7px + 1vw)", margin: "1%", maxWidth: "60%", alignSelf: sender ? "flex-end" : "flex-start", borderRadius: "17px", padding: "14px", textAlign: "start", color: "white", backgroundColor: sender ? "var(--primaryColor)" : "var(--secondaryColor)" }} >

    {message}


  </div>




}











function App() {


  return (<div>
        <div className="phoneText" id="messageBoxOverlay">
      <div id="messageBox">
        <p >
          Unable to detect the platform. Please try this on a mobile device.
        </p>
        <button onClick={()=>document.getElementById("messageBoxOverlay").style.display = "none"} id="messageBoxButton">
          Okay
        </button>
      </div>
    </div>
    <div className="phoneText" id="contactBoxOverlay">
        <div id="contactBox">
            <div  onClick={()=>{document.getElementById('contactBoxOverlay').style.display = "none";
}} id="contactBoxButton">
                <svg id="contactBoxClose" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                    <path
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                </svg>
            </div>
            <div>
                <svg onClick={()=>{window.open('https://instagram.com/marve.app/')}} class="socialIcon" id="Layer_1"
                    data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.33 533.33">
                    <path
                        d="M865.06,290.05c-1.29-28.38-5.8-47.76-12.39-64.73a136.39,136.39,0,0,0-78-78c-17-6.59-36.35-11.1-64.73-12.39s-37.53-1.61-110-1.61-81.5.31-109.95,1.61-47.76,5.8-64.73,12.39a136.39,136.39,0,0,0-78,78c-6.59,17-11.1,36.35-12.39,64.73s-1.61,37.53-1.61,110,.31,81.5,1.61,110,5.8,47.76,12.39,64.73a136.39,136.39,0,0,0,78,78c17,6.59,36.35,11.1,64.73,12.39s37.53,1.61,110,1.61,81.5-.31,110-1.61,47.76-5.8,64.73-12.39a136.39,136.39,0,0,0,78-78c6.59-17,11.1-36.35,12.39-64.73s1.61-37.53,1.61-109.95S866.36,318.5,865.06,290.05Zm-48,217.71c-1.18,26-5.53,40.12-9.18,49.51a88.34,88.34,0,0,1-50.61,50.61c-9.39,3.65-23.51,8-49.51,9.18-28.12,1.29-36.55,1.56-107.76,1.56s-79.64-.27-107.76-1.56c-26-1.18-40.12-5.53-49.51-9.18a88.34,88.34,0,0,1-50.61-50.61c-3.65-9.39-8-23.51-9.18-49.51-1.29-28.12-1.56-36.56-1.56-107.76s.27-79.64,1.56-107.76c1.18-26,5.53-40.12,9.18-49.51a88.34,88.34,0,0,1,50.61-50.61c9.39-3.65,23.51-8,49.51-9.18,28.12-1.29,36.56-1.56,107.76-1.56h0c71.2,0,79.64.27,107.76,1.56,26,1.18,40.12,5.53,49.51,9.18a88.34,88.34,0,0,1,50.61,50.61c3.65,9.39,8,23.51,9.18,49.51,1.29,28.12,1.56,36.56,1.56,107.76S818.35,479.64,817.06,507.76Z"
                        transform="translate(-333.33 -133.33)" />
                    <path
                        d="M600,263.06A136.94,136.94,0,1,0,736.94,400,136.94,136.94,0,0,0,600,263.06Zm0,225.83A88.89,88.89,0,1,1,688.89,400,88.89,88.89,0,0,1,600,488.89Z"
                        transform="translate(-333.33 -133.33)" />
                    <path d="M774.35,257.65a32,32,0,1,1-32-32,32,32,0,0,1,32,32"
                        transform="translate(-333.33 -133.33)" />
                </svg>
                <svg  onClick={()=>{window.open('https://facebook.com/marveapp/')}} class="socialIcon" id="Layer_1"
                    data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1017.78">
                    <path
                        d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z" />
                </svg>
                <svg onClick={()=>{window.open('https://twitter.com/marve_app/')}} class="socialIcon" id="Logo_FIXED"
                    data-name="Logo FIXED" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 203.11">
                    <path
                        d="M78.62,244.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,250,65.47a102.24,102.24,0,0,1-29.46,8.07,51.46,51.46,0,0,0,22.55-28.37,102.53,102.53,0,0,1-32.57,12.45,51.31,51.31,0,0,0-87.36,46.77A145.62,145.62,0,0,1,17.4,50.81a51.33,51.33,0,0,0,15.88,68.47A50.84,50.84,0,0,1,10,112.86v.65a51.31,51.31,0,0,0,41.15,50.28,51.2,51.2,0,0,1-23.16.88,51.37,51.37,0,0,0,47.92,35.62A102.92,102.92,0,0,1,0,221.55a145.22,145.22,0,0,0,78.62,23"
                        transform="translate(0 -41.48)" />
                </svg>
                <svg  onClick={()=>{window.open('https://www.linkedin.com/company/enabol/')}} class="socialIcon" id="Layer_1"
                    data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 719.42 611.89">
                    <path
                        d="M681,532.47c23.24,0,41.43,17.86,41.17,40.81A41,41,0,0,1,681,614.13h-.22C658,613.5,640,596,639.91,573.38,639.86,548.9,660.55,531.24,681,532.47Zm-37.3,40.68a37.39,37.39,0,1,0,74.77,1.22c0-.41,0-.81,0-1.22,0-20.3-16.9-37.43-37.13-37.67S643.67,552.55,643.66,573.15Z"
                        transform="translate(-2.71 -2.24)" />
                    <path
                        d="M610.59,46a51.94,51.94,0,0,0-1.89-12.82c-3.89-14.92-19.87-30.92-43.4-30.92q-218.19.15-436.38.15c-27.5,0-55-.16-82.5,0C21.33,2.64,2.71,21.53,2.71,46.53V565.84a47.91,47.91,0,0,0,.44,6.77c3.13,21.55,22.42,37.43,45.22,37.3,85.91-.48,171.82-.19,257.73-.19H470c32.43,0,64.87.15,97.29-.06,24.72-.17,43.41-19.23,43.41-43.81Q610.75,305.91,610.59,46ZM183.67,514.47c0,5.46-2,6.72-7,6.72q-39-.27-77.91,0c-2.39,0-4.06-.27-5.17-1.09-1.45-1-2-2.86-2-6,.25-46.22.16-92.46.16-138.7V237.26c0-7.86,0-7.87,7.59-7.87,25.78,0,51.56.11,77.34-.1a18.74,18.74,0,0,1,2.51.14c3.18.42,4.46,2,4.46,6.46Q183.45,375.18,183.67,514.47ZM150.9,188.74a52.93,52.93,0,0,1-13.17,1.69h-.52l-.85,0-1.71,0-.59,0c-.73-.05-1.45-.1-2.18-.17h-.1a52.32,52.32,0,0,1-46.92-51.47v-.58c0-24.12,15.35-44.17,36.88-50.83A52.81,52.81,0,1,1,150.9,188.74ZM521.69,513.85c0,2.37-.29,4-1,5.14-1.06,1.72-3,2.27-6.44,2.24-25.4-.24-50.81-.1-76.22-.11-7.89,0-8-.1-8-7.9,0-47.77.23-95.55-.18-143.32a167.7,167.7,0,0,0-3.77-35.38c-4.77-21.06-18.3-31.26-39.93-31.5a80.25,80.25,0,0,0-20.2,2c-12.35,3.1-20.45,9.94-25.73,19a66,66,0,0,0-7.35,21.39,166.63,166.63,0,0,0-2.39,28.81c-.23,46.44-.11,92.89-.11,139.34,0,4.7,0,6.46-1.85,7.12-1.11.41-2.89.42-5.73.42-25.6,0-51.2-.13-76.79.11-5.59,0-7.52-1.48-7.51-7.36q0-11.61,0-23.21.09-115.28.1-230.55,0-11.62,0-23.24c0-3.28,0-5.12.64-6.15.81-1.35,2.65-1.35,6.89-1.35,24.84,0,49.69.09,74.53-.09,4.6,0,6.4,1.26,6.27,6.07,0,1.16,0,2.32-.06,3.48-.13,9.43,0,18.89,0,28.35,8-11.2,17.25-20.89,28.43-28.46,1.89-1.29,3.83-2.52,5.84-3.68,18.25-10.51,37.86-15,58.86-12.6,11.78,1.33,23.83,2.15,35.17,5.33,33,9.22,51.77,32.76,59.17,65a266.76,266.76,0,0,1,6.91,55C522.12,403.13,521.46,458.5,521.69,513.85Z"
                        transform="translate(-2.71 -2.24)" />
                    <path
                        d="M686.09,576l11.41,17.57-1,1.54c-1.85-1.1-4.23-1.8-5.44-3.39a103.68,103.68,0,0,1-8-12.41c-2-3.6-5.1-5-8.43-3.56-1.49.66-2.16,4.09-2.45,6.36-.41,3.17.05,6.44-.22,9.63-.08,1-1.32,1.87-2,2.79-1-.87-2.78-1.73-2.8-2.61-.19-12.3-.22-24.61,0-36.91,0-1.06,2.36-2.9,3.69-3a143.47,143.47,0,0,1,16.46.09c6.51.4,10.17,4.06,10.88,10.22.63,5.42-2.41,10-8.13,12.12Zm-14-20.11c0,4.74-.22,8.53.13,12.25.11,1.18,1.68,3.06,2.72,3.16a44.2,44.2,0,0,0,10.72-.12c4.78-.71,7.17-3.7,6.66-8.78-.48-4.78-3.51-6.36-7.72-6.48-3.93-.11-7.86,0-12.52,0Z"
                        transform="translate(-2.71 -2.24)" />
                </svg>
                <svg onClick={()=>{window.location='mailto:contact@marve.app'}} class="socialIcon"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z" />
                </svg>

            </div>
            <p>
                Follow us on our social platforms to keep in touch. Feel free to contact us using any of the channels
                above with your thoughts, suggestions, and feedback.

            </p>
        </div>
    </div>
    <div id="container">
      <NavBar />
      <Pages />
      <Footer />
    </div>

  </div>
  );
}




export default App;
